import React from "react"
import Hakusi from '../../layouts/hakusi'


export default () => (


	<Hakusi>

	<section class="butinuki t-center">

<div id="mc_embed_signup">

	<form action="https://tokyo.us18.list-manage.com/subscribe/post?u=0d71500e8a0f577cff8987305&amp;id=ed7c53b086" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>

		<div id="mc_embed_signup_scroll">

			<div class="mc-field-group">
				<label for="mce-EMAIL">メールアドレスを入力</label>
				<input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required placeholder='メールアドレスを入力'/>
			</div>

			<div class="mc-field-group input-group">
				<input type="hidden" value="2" name="group[3397][2]" id="mce-group[3397]-3397-0"/>
			</div>

			<div id="mce-responses" class="clear">
				<div class="response" id="mce-error-response" style={{display:`none`}}/>
				<div class="response" id="mce-success-response" style={{display:`none`}}/>
			</div>

			<div style={{position: `absolute`, left: -5000}} aria-hidden="true">
				<input type="text" name="b_0d71500e8a0f577cff8987305_ed7c53b086" tabindex="-1" value=""/>
			</div>
			<div class="clear">
				<input type="submit" value="送信" name="subscribe" id="mc-embedded-subscribe" class="button"/>
			</div>

		</div>

	</form>
</div>



	</section>


</Hakusi>
)
